<template>
  <div
    class="calendar_component"
    :style="{ width: width, borderColor: showPopup ? '#0062ff' : '#dcdfe6' }"
  >
    <div class="default" @click.stop="calendarClick">
      <span class="el-icon-date" style="color: #778CA2"></span>
      <span class="date" v-if="value">{{ value }}</span>
      <span class="placeholder" v-else>{{ placeholder }}</span>
    </div>

    <div class="select_area" v-if="showPopup">
      <div class="title">{{ getTitle }}</div>

      <div class="show_box">
        <ul
          class="month_day_select"
          :style="{ transform: 'translateX(' + -(step - 1) * 300 + 'px)' }"
        >
          <!-- 起始月份 -->
          <li class="month_render">
            <a
              class="month"
              href="javascript:void(0);"
              v-for="item in monthList"
              :key="item.id"
              @click.stop="getDaysList(item.id, 1, 2)"
              >{{ item.name }}</a
            >
          </li>
          <!-- 起始日 -->
          <li class="day_render">
            <div class="empty"></div>
            <div class="empty"></div>
            <a
              class="day"
              href="javascript:void(0);"
              v-for="item in daysList1"
              :key="item.id"
              @click.stop="endDayClick(1, item.name)"
              >{{ item.name }}</a
            >
          </li>
          <!-- 结束月 -->
          <li class="month_render">
            <a
              class="month"
              href="javascript:void(0);"
              v-for="item in monthList"
              :key="item.id"
              @click.stop="getDaysList(item.id, 2, 4)"
              >{{ item.name }}</a
            >
          </li>
          <!-- 结束日 -->
          <li class="day_render">
            <div class="empty"></div>
            <div class="empty"></div>
            <a
              class="day"
              href="javascript:void(0);"
              v-for="item in daysList2"
              :key="item.id"
              @click="endDayClick(2, item.name)"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
      // 月份
      monthList: [
        {
          id: 1,
          name: '一月'
        },
        {
          id: 2,
          name: '二月'
        },
        {
          id: 3,
          name: '三月'
        },
        {
          id: 4,
          name: '四月'
        },
        {
          id: 5,
          name: '五月'
        },
        {
          id: 6,
          name: '六月'
        },
        {
          id: 7,
          name: '七月'
        },
        {
          id: 8,
          name: '八月'
        },
        {
          id: 9,
          name: '九月'
        },
        {
          id: 10,
          name: '十月'
        },
        {
          id: 11,
          name: '十一月'
        },
        {
          id: 12,
          name: '十二月'
        }
      ],
      // 当前进行的步骤
      step: 1,
      // 日选择数据1
      daysList1: [],
      // 日期选择器2
      daysList2: [],
      // 选择结果
      startMonth: '',
      startDay: '',
      endMonth: '',
      endDay: ''
    }
  },
  props: {
    width: {
      type: String,
      default: '200px'
    },
    value: String,
    placeholder: {
      type: String,
      default: '请选择区间'
    }
  },
  methods: {
    // 点击日历
    calendarClick() {
      this.startMonth = ''
      this.startDay = ''
      this.endMonth = ''
      this.endDay = ''
      this.step = 1
      this.showPopup = true
    },

    // 获取天数
    getDaysList(month, type, step) {
      if (type === 1) {
        this.startMonth = month
      } else {
        this.endMonth = month
      }
      const days = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month - 1]
      const daysList = []
      for (let i = 0; i < days; i++) {
        daysList.push({
          id: (i + 1 + '').padStart(2, '0'),
          name: i + 1
        })
      }
      if (type === 1) {
        this.daysList1 = daysList
      } else {
        this.daysList2 = daysList
      }
      this.step = step
    },

    // 日选择器点击
    endDayClick(type, day) {
      if (type === 1) {
        this.startDay = day
        this.step = 3
      } else {
        this.endDay = day
        this.showPopup = false
        this.triggerEvent()
      }
    },

    // 告诉父组件, 选择结果
    triggerEvent() {
      const startMonthStr = (this.startMonth + '').padStart(2, '0')
      const startDayStr = (this.startDay + '').padStart(2, '0')
      const endMonthStr = (this.endMonth + '').padStart(2, '0')
      const endDayStr = (this.endDay + '').padStart(2, '0')
      // this.$emit(
      //   'select',
      //   `${startMonthStr}-${startDayStr}—${endMonthStr}-${endDayStr}`
      // )
      this.$emit(
        'update:value',
        `${startMonthStr}-${startDayStr}—${endMonthStr}-${endDayStr}`
      )
      this.$emit('change')
    },

    // html文档点击
    docClick() {
      this.showPopup = false
    },

    // 添加全局事件监听
    addEvent() {
      document.addEventListener('click', this.docClick)
    },

    // 移除事件监听
    removeEvent() {
      document.removeEventListener('click', this.docClick)
    }
  },

  created() {
    this.addEvent()
  },

  beforeDestroy() {
    this.removeEvent()
  },

  computed: {
    // 动态计算标题
    getTitle() {
      if (this.startMonth) {
        let rangeStr = (this.startMonth + '').padStart(2, '0')
        rangeStr += '-'
        if (this.startDay) {
          rangeStr += (this.startDay + '').padStart(2, '0')
          rangeStr += ' — '
        }
        if (this.endMonth) {
          rangeStr += (this.endMonth + '').padStart(2, '0')
          rangeStr += '-'
        }
        return rangeStr
      } else {
        return '请选择时间区间'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar_component {
  position: relative;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #dcdfe6;
  background-color: #fff;
  transition: border 0.1s ease-out;

  .default {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    cursor: pointer;

    .date {
      font-size: 12px;
      font-family: AlibabaPuHuiTi;
      font-weight: 400;
      color: #12203e;
      padding-left: 16px;
    }

    .placeholder {
      font-size: 12px;
      font-family: AlibabaPuHuiTi;
      font-weight: 400;
      color: #ccc;
      padding-left: 16px;
    }
  }

  .select_area {
    position: absolute;
    top: calc(100% + 13px);
    left: 0;
    z-index: 3000;
    width: 326px;
    height: 316px;
    color: #606266;
    border: 1px solid #e4e7ed;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    padding: 0 12px 12px;

    .title {
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: solid 1px #ebeef5;
      font-size: 16px;
      font-weight: 500;
    }

    .show_box {
      height: 240px;
      width: 300px;
      margin-top: 5px;
      overflow: hidden;

      .month_day_select {
        width: 1200px;
        height: 240px;
        display: flex;
        transition: all 0.3s;

        .month_render {
          flex: 0 0 300px;
          height: 240px;
          display: flex;
          flex-wrap: wrap;

          .month {
            flex: 0 0 75px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #606266;

            &:hover {
              color: #0062ff;
            }
          }
        }

        .day_render {
          flex: 0 0 300px;
          height: 240px;
          display: flex;
          flex-wrap: wrap;

          .empty {
            flex: 0 0 42.857px;
            height: 48px;
          }

          .day {
            flex: 0 0 42.857px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #606266;

            &:hover {
              color: #0062ff;
            }
          }
        }
      }
    }
  }
}
</style>
