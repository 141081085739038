<template>
  <keep-alive>
    <Component :is="getComponent" ref="pageRef" />
  </keep-alive>
</template>

<script>
import page1 from './page1.vue'
import page2 from './page2.vue'

export default {
  name: 'ctrl_rule',
  data() {
    return {
      // 1规则页面 2编辑规则页面
      page: 1
    }
  },
  methods: {
    // 修改page
    changePage(page, info) {
      if (this.page === page) return
      this.page = page
      this.$nextTick(() => {
        if (page === 2) {
          this.$refs.pageRef.getData(info)
        } else {
          this.$refs.pageRef.getRuleList()
        }
      })
    }
  },
  computed: {
    getComponent() {
      if (this.page === 1) {
        return page1
      } else {
        return page2
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
