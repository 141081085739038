import { GetProjectApi } from '@/api'
export default {
  data() {
    return {
      // 项目列表
      projectList: []
    }
  },
  methods: {
    async getProjectList(p) {
      const params = { ...p }
      const { ret, data, msg } = await GetProjectApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.projectList = data.data
      return data.data
    }
  }
}

// addr: ""
// cnt_building: {cnt: 3}
// cnt_room: {cnt: 0}
// id: 100
// id_operator: 117
// name_operator: "前端运营商"
// name_project: "前端项目"
// time_create: "2022-06-07 10:32:43"
// time_update: "2022-06-07 10:32:43"
