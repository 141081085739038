import { GetBuildApi } from '@/api'
export default {
  data() {
    return {
      buildingList: []
    }
  },
  methods: {
    async getBuildingList(p) {
      const params = {
        ...p
      }
      const { ret, data, msg } = await GetBuildApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.buildingList = data.data
      return data.data
    }
  }
}

// cnt_room: {cnt: 0}
// id: 100
// id_project: 100
// name_building: "前端楼栋"
// name_operator: "前端运营商"
// name_project: "前端项目"
// state: 1
// time_create: "2022-06-07 10:50:08"
// time_update: "2022-06-07 10:50:08"
