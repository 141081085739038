<template>
  <el-dialog
    title="添加设备"
    :visible.sync="dialogVisible"
    width="600px"
    @closed="clearData"
    center
  >
    <div class="build_select">
      <el-select
        v-model="id_building"
        placeholder="请选择楼栋"
        @change="getDeviceList"
      >
        <el-option
          v-for="item in buildingList"
          :key="item.id"
          :label="item.name_building"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="tree_box add_device_dialog_tree">
      <el-scrollbar style="height:100%">
        <el-tree
          show-checkbox
          :data="treeData"
          :props="{ label: 'name', children: 'children' }"
          ref="treeRef"
          default-expand-all
        ></el-tree>
      </el-scrollbar>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetTreeDeviceListApi, AddDevice2RuleApi } from '@/api'
import building from '@/mixins/building.js'

export default {
  mixins: [building],
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 项目id
      id_project: '',
      // 楼栋id
      id_building: '',
      // 树形结构
      treeData: []
    }
  },
  methods: {
    add(id_project, id_task) {
      this.id_task = id_task
      this.id_project = id_project
      this.getBuildingList({ id_project })
      this.dialogVisible = true
    },

    // 清除数据
    clearData() {
      this.treeData = []
      this.id_building = ''
    },

    // 获取设备列表
    async getDeviceList() {
      if (!this.id_building) return
      const params = {
        id_building: this.id_building
      }
      const { ret, data, msg } = await GetTreeDeviceListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      const treeData = []
      data.map((i, index) => {
        const obj1 = {
          id: i.floor,
          name: i.floor + 'F'
        }
        // 楼层
        const roomList = []
        i.room_list.map((j, idx) => {
          const obj2 = {
            id: -idx,
            name: j.room_name
          }
          // 房间
          const deviceList = []
          j.device_list.map(k => {
            // 设备
            const obj3 = {
              id: k.id_device,
              name: k.addr
            }
            deviceList.push(obj3)
          })
          obj2.children = deviceList
          roomList.push(obj2)
        })
        obj1.children = roomList
        treeData.push(obj1)
      })
      this.treeData = treeData
    },

    async save() {
      const selected = this.$refs.treeRef.getCheckedNodes()
      if (!selected.length) {
        return this.$message.warning('暂无选中设备!')
      }
      const ids = []
      selected.map(i => {
        if (Object.prototype.toString.call(i.id) === '[object String]') {
          ids.push(i.id)
        }
      })
      const params = {
        device_list: ids,
        id_task: this.id_task
      }
      const { ret, data, msg } = await AddDevice2RuleApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('refresh', 2)
    }
  }
}
</script>

<style lang="scss" scoped>
.build_select {
  padding-left: 37px;
}

.tree_box {
  padding: 10px 30px;
  height: 360px;
}
</style>

<style lang="scss">
.add_device_dialog_tree {
  .el-tree-node__content {
    height: 40px !important;
  }
}
</style>
