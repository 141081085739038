<template>
  <el-dialog
    :title="isEdit ? '修改空调控制规则' : '新增空调控制规则'"
    :visible.sync="dialogVisible"
    width="678px"
    center
    @closed="clearForm"
    :close-on-click-modal="false"
  >
    <el-form :model="ruleForm" :rules="rules" ref="formRef" label-width="80px">
      <el-row>
        <template v-if="!isEdit">
          <el-col :span="12" v-if="level === 100">
            <el-form-item label="运营商" prop="id_operator">
              <el-select
                v-model="ruleForm.id_operator"
                placeholder="请选择运营商"
                class="w100"
                @change="ruleForm.id_project = ''"
              >
                <el-option
                  v-for="item in operatorList"
                  :key="item.id_operator"
                  :label="item.name_operator"
                  :value="item.id_operator"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目" prop="id_project">
              <el-select
                v-model="ruleForm.id_project"
                placeholder="请选择项目"
                class="w100"
              >
                <el-option
                  v-for="item in computedProject"
                  :key="item.id"
                  :label="item.name_project"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>
        <template v-else>
          <el-col :span="12" v-if="level === 100">
            <el-form-item label="运营商">
              <el-input v-model="ruleForm.name_operator" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目">
              <el-input v-model="ruleForm.name_project" disabled></el-input>
            </el-form-item>
          </el-col>
        </template>
        <el-col :span="12">
          <el-form-item label="规则名称" prop="name">
            <el-input
              v-model.trim="ruleForm.name"
              placeholder="请输入规则名称"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm(false)">保 存</el-button>
      <el-button type="success" @click="confirm(true)">保存并设置</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  check_operator,
  check_project,
  check_rulename
} from '@/utils/validator.js'
import { AddRuleApi, UpdateRuleApi } from '@/api'

export default {
  data() {
    return {
      // 权限级别
      level: '',
      // 是否是修改规则
      isEdit: false,
      // 是否显示弹窗
      dialogVisible: false,
      // 运营商列表
      operatorList: [],
      // 运营商
      id_operator: '',
      // 项目列表
      projectList: [],
      // 项目
      id_project: '',
      // 添加规则表单
      ruleForm: {
        id_task: '',
        name: '',
        id_operator: '',
        id_project: ''
      },
      // 添加规则表单校验
      rules: {
        name: check_rulename,
        id_operator: check_operator,
        id_project: check_project
      }
    }
  },
  methods: {
    // 添加
    add(projectList, operatorList) {
      this.level = this.$store.getters.level
      this.operatorList = operatorList
      this.projectList = projectList
      this.isEdit = false
      this.dialogVisible = true
    },

    // 修改
    edit(row) {
      this.ruleForm.id_task = row.id
      this.isEdit = true
      this.dialogVisible = true
      this.$nextTick(() => {
        this.ruleForm.name_operator = row.name_operator
        this.ruleForm.name_project = row.name_project
        this.ruleForm.name = row.name
      })
    },

    // 点击确定
    confirm(toSetting = false) {
      this.$refs.formRef.validate(_ => {
        if (!_) return
        if (this.isEdit) {
          this.editRule(toSetting)
        } else {
          this.addRule(toSetting)
        }
      })
    },

    // 添加规则
    async addRule(toSetting) {
      const params = {
        id_project: this.ruleForm.id_project,
        name: this.ruleForm.name
      }
      if (this.ruleForm.id_operator) {
        params.id_operator = this.ruleForm.id_operator
      }
      const { ret, data, msg } = await AddRuleApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('添加成功')
      this.dialogVisible = false

      if (toSetting) {
        this.$emit('refresh', data)
      } else {
        this.$emit('refresh')
      }
    },

    // 编辑规则 是否去设置界面
    async editRule(toSetting) {
      const params = {
        id_task: this.ruleForm.id_task,
        name: this.ruleForm.name
      }
      const { ret, data, msg } = await UpdateRuleApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('编辑成功')
      this.dialogVisible = false
      if (toSetting) {
        this.$emit('refresh', { id: this.ruleForm.id_task })
      } else {
        this.$emit('refresh')
      }
    },

    // 清除表单校验
    clearForm() {
      try {
        this.$refs.formRef.resetFields()
      } catch (e) {}
    }
  },
  computed: {
    // 计算项目
    computedProject() {
      if (this.level === 100) {
        if (this.ruleForm.id_operator) {
          return this.projectList.filter(i => {
            return i.id_operator === this.ruleForm.id_operator
          })
        } else {
          return this.projectList
        }
      } else {
        return this.projectList
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
